/* Types */
export interface GraphQlErrorLocation {
  column: number;
  line: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export enum SampleTypeCodeEnum {
  WHOLE = 'WHOLE',
  PLASMA = 'PLASMA',
  SERUM = 'SERUM',
}

export enum SampleTypeNameEnum {
  WHOLE = 'Whole Blood',
  PLASMA = 'Plasma Blood',
  SERUM = 'Serum Blood',
}

export enum AliquotStatus {
  SAT,
  CLT,
  CTM,
  HEM,
  SHV,
  COC,
  OTH,
}

export enum SampleStatus {
  SAT,
  EQF,
  LBE,
  PST,
  CLT,
  CTM,
  HEM,
  ICT,
  LIP,
  LYS,
  SNP,
}

/* Payloads */

export interface CreateSampleBatchInput {
  samples: any;
  sampleType: SampleTypeCodeEnum | null;
}

export type CreateCryoBoxInput = {
  aliquotPositions: string[];
  bioBanked: boolean;
  cryoboxBarcode?: string;
  freezerShelfNo: string;
  freezerId: string;
  height: string;
  rackPositionNo: string;
  sampleType: SampleTypeCodeEnum;
  serialNo: string;
  shelfRackNo: string;
  width: string;
};

export interface FindSampleBatchPayload {
  searchTerm?: string | null;
}

export interface LinkRGCBarcodeInput {
  aliquotId: string;
  rgcBarcode: string;
}

export type UpdateCryoBoxInput = {
  aliquotPositions: string[];
  bioBanked: boolean;
  cryoboxBarcode: string;
  freezerId: string;
  freezerShelfNo: string;
  rackPositionNo: string;
  cryoboxUuid: string;
  shelfRackNo: string;
};

/* Interfaces */
export interface Aliquot {
  barcode: string;
  createdBy: string;
  createdTs: string;
  lastUpdatedBy: string;
  mismatch: boolean;
  sampleBarcode: string;
  sampleBatchId: string;
  status: AliquotStatus;
  updatedTs: string;
  volume: number;
}

export interface CryoboxSearchResult {
  data: Cryobox[];
  count: number;
}

export interface Cryobox {
  aliquotPositions: string[];
  aliquotPositionTimestamps?: string[];
  barcodePositions?: string[];
  barcodeLinkTimestamps?: string[];
  bioBanked: boolean;
  cryoboxBarcode?: string;
  createdBy: string;
  createdTs: string;
  freezerShelfNo: string;
  freezer: Freezer;
  height: number;
  lastUpdatedBy: string;
  rackPositionNo: string;
  sampleType: SampleTypeCodeEnum;
  serialNo: string;
  cryoboxUuid: string;
  shelfRackNo: string;
  updatedTs: string;
  width: number;
}

export interface Freezer {
  freezerId: string;
  name: string;
  serialNo: string;
  temp: string;
}

export interface Sample {
  barcode: string;
  status: SampleStatus;
  volume: number;
  aliquots: Aliquot[];
}

export interface SampleBatch {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  samples: [Sample];
  createdTs: string;
  updatedTs: string;
  createdBy: string;
  lastUpdatedBy: string;
}

export interface SampleBatchLookup {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
}

/* Response */
export type CreateCryoboxResponse = {
  data?: { createCryobox: Cryobox };
  errors?: GraphQlError[];
};

export type CreateSampleBatchResponse = {
  data?: { createSampleBatch: SampleBatch };
  errors?: GraphQlError[];
};

export type FindCryoboxesResponse = {
  data?: {
    findCryoboxes: CryoboxSearchResult;
  };
  errors?: GraphQlError[];
};

export type FindSampleBatchesResponse = {
  data?: { findSampleBatches: SampleBatchLookup[] };
  errors?: GraphQlError[];
};

export type GetCryoboxResponse = {
  data?: { getCryobox: Cryobox };
  errors?: GraphQlError[];
};

export type GetSampleBatchResponse = {
  data?: { getSampleBatch: SampleBatch };
  errors?: GraphQlError[];
};

export type LinkRGCBarcodeResponse = {
  data?: { linkRGCBarcode: Freezer[] };
  errors?: GraphQlError[];
};

export type ListFreezersResponse = {
  data?: { listFreezers: Freezer[] };
  errors?: GraphQlError[];
};

export type UpdateCryoboxResponse = {
  data?: { updateCryobox: Cryobox };
  errors?: GraphQlError[];
};
